<template>
  <div>
    <b-modal
      id="edit-shipment"
      ref="modal"
      header-class="headerModal"
      centered
      :title="$t('EditShipment')"
      hide-footer
      @close="onCancel"
    >
      <b-form>
        <b-form-group
          :label="$t('DateOfShipment')"
        >
          <date-picker
            v-model="dateOfPayment"
            type="date"
            :clearable="false"
            format="YYYY-MM-DD"
            value-type="format"
            style="width:100%"
          />
          <b-form-group />
        </b-form-group>
        <b-form-group
          :label="$t('Amount')"
        >
          <b-form-input
            v-model.number="amount"
            type="number"
            :placeholder="$t('Amount')"
          />
          <b-form-group />
          <p
            v-if="buttonSubmited && (amount == null || amount === '')"
            style="color:red;"
          >
            {{ $t('RequiredField') }}
          </p>
        </b-form-group>
        <b-form-group
          :label="$t('Account')"
        >
          <vue-select
            v-model="accountId"
            :options="accountDetails"
            label="accountName"
            :clearable="false"
            :placeholder="$t('SelectAccount')"
            style="width:100%"
          />
          <p
            v-if="buttonSubmited && accountId.accountId == null"
            style="color:red;"
          >{{ $t('SelectAccount') }}</p>
        </b-form-group>
        <b-form-group
          :label="$t('Notes')"
        >
          <b-form-textarea
            v-model="notes"
            :placeholder="$t('Write...')"
          />
        </b-form-group>
        <div class="buttonsEverywhere">
          <button
            type="button"
            variant="none"
            class="buttonSubmit"
            style="margin-top: 10px;"

            @click="onSubmit"
          >
            {{ $t('Submit') }}
          </button>
          <b-button
            type="button"
            variant="danger"
            class="buttonCancel"
            style="margin-right: 15px; margin-top: 10px; background: white; color: black; border-color: white; font-weight: 400;"

            @click="onCancel"
          >
            {{ $t('Cancel') }}
          </b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
// import moment from 'moment'
import DatePicker from 'vue2-datepicker';
import {
} from 'vuelidate/lib/validators';
import 'vue2-datepicker/index.css';

export default {
  components: {
    DatePicker,
  },
  props: ['editPayment', 'allTotalPaid', 'priceOrderId'],
  data() {
    return {
      dateOfPayment: null,
      amount: null,
      accountId: null,
      notes: null,
      paymentTrackId: null,
      buttonSubmited: false,
    }
  },
  computed: {
    ...mapGetters([
      'getAccountDetails',
    ]),
    accountDetails() {
      return this.getAccountDetails.map((item) => {
        return {
          accountName: item.name,
          accountId: item.accountId,
        }
      })
    },
  },
  watch: {
    editPayment(value) {
      if (value) {
        this.dateOfPayment = value.paymentDate
        this.amount = value.amount
        this.accountId = {
          accountId: value.accountId,
          accountName: value.accountName,
        }
        this.paymentTrackId = value.id
      }
    },
    amount(value) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (value < 0 || value > this.allTotalPaid) {
          this.amount = 0
        }
      }, 100);
    },
  },
  methods: {

    onSubmit() {
      this.buttonSubmited = true
      try {
        if (this.buttonSubmited && (this.amount == null || this.amount === '')) {
          return;
        }
        if (this.buttonSubmited && this.accountId.accountId == null) {
          return;
        }
        const editPayment = {
          shipmentId: this.paymentTrackId,
          newAmount: this.amount,
          notes: this.notes,
          paymentDate: this.dateOfPayment,
          accountId: this.accountId.accountId,
          orderId: this.priceOrderId,
          price: this.editPayment.amount,
        }
        this.$emit('editPaymentTotal', editPayment)
      } catch (error) {
        console.log(error)
      }
      this.$refs.modal.hide();
      this.onReset()
    },
    onCancel() {
      this.$refs.modal.hide();
      this.onReset()
    },
    onReset() {
      this.buttonSubmited = false
      this.dateOfPayment = this.editPayment.paymentDate
      this.amount = this.editPayment.amount
      this.accountId = {
        accountId: this.editPayment.accountId,
        accountName: this.editPayment.accountName,
      }
      this.paymentTrackId = this.editPayment.id
    },
  },
}
</script>

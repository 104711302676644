<template>
  <div>
    <b-modal
      id="edit-order-price"
      ref="modal"
      header-class="headerModal"
      centered
      :title="$t('EditPayment')"
      hide-footer
      no-close-on-backdrop
      @close="onCancel"
    >
      <b-form>
        <b-form-group :label="$t('Price')">
          <b-form-input
            v-model.number="form.price"
            type="number"
            @input="calculateFromPrice(form.price)"
          />
        </b-form-group>
        <label>{{ $t('Discount') }}</label>
        <div
          style="display:flex;gap:5px;"
        >
          <b-form-group
            style="width:90%"
          >
            <b-form-input
              v-model.number="form.discount"
              type="number"
              @input="calculateFromDiscount(form.discount)"
            />
          </b-form-group>
          <b-form-select
            v-model="currency"
            dropup
            required
            :options="currencies"
            :aria-placeholder="$t('SelectOrderType')"
            aria-describedby="input-1-live-feedback"
            class="currency"
            style="width:10%"
          />
        </div>
        <b-form-group :label="$t('PriceWithDiscount')">
          <b-form-input
            v-model.number="form.priceWithDiscount"
            type="number"
            @input="calculateFromPriceWithDiscount(form.priceWithDiscount)"
          />
        </b-form-group>
        <b-form-group :label="$t('PaidAmount')">
          <b-form-input
            v-model.number="form.paidAmount"
            type="number"
            disabled
          />
        </b-form-group>
        <p
          v-if="buttonSubmited && form.priceWithDiscount < form.paidAmount"
          style="color:red; margin-bottom: 5px;;"
        >
          {{ $t('Paid cannot be bigger than price or price with discount') }}
        </p>
        <div class="buttonsEverywhere">
          <button
            type="button"
            variant="none"
            class="buttonSubmit"
            style="margin-top: 10px;"

            @click="onSubmit"
          >
            {{ $t('Submit') }}
          </button>
          <b-button
            type="button"
            variant="danger"
            class="buttonCancel"
            style="margin-right: 15px; margin-top: 10px; background: white; color: black; border-color: white; font-weight: 400;"

            @click="onCancel"
          >
            {{ $t('Cancel') }}
          </b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
// import moment from 'moment'
// import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  components: {
    // DatePicker,
  },
  props: ['paymentPrice'],
  data() {
    return {
      currency: '€',
      currencies: ['€', '$', '%'],
      form: {
        price: 0,
        discount: 0,
        priceWithDiscount: 0,
        paidAmount: 0,
      },
      buttonSubmited: false,
    }
  },
  computed: {
    ...mapGetters([
    ]),
  },
  watch: {
    paymentPrice(value) {
      this.form.price = value.price
      this.form.discount = value.discount
      this.form.priceWithDiscount = value.priceWithDiscount
      this.form.paidAmount = value.paidAmount
    },
    currency(value) {
      if (value) {
        this.updateCalculations();
      }
    },
  },
  methods: {
    updateCalculations() {
      if (this.currency != '%') {
        if (this.form.discount > this.form.price) {
          this.form.discount = 0
        } else {
          this.form.priceWithDiscount = this.form.price - this.form.discount;
        }
      } else {
        this.form.priceWithDiscount = this.form.price - (this.form.discount / 100) * this.form.price;
      }
    },
    calculateFromPrice(value) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (this.currency != '%') {
          if (value < 0 || value == '') {
            this.form.price = 0
            this.form.priceWithDiscount = 0
          }
          if (value > this.form.discount) {
            this.form.priceWithDiscount = value - this.form.discount
          } else {
            this.form.discount = 0
          }
        } else {
          if (value < 0 || value == '') {
            this.form.price = 0
            this.form.priceWithDiscount = 0
            this.form.discount = 0
          }
          this.form.priceWithDiscount = value - (this.form.discount / 100) * value
        }
      }, 10);
    },
    calculateFromDiscount(value) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (this.currency != '%') {
          if (value < 0 || value == '') {
            this.form.discount = 0
          }
          if (value <= this.form.price) {
            this.form.priceWithDiscount = this.form.price - value
            this.form.discount = value
          } else {
            this.form.discount = 0
            this.form.priceWithdiscount = 0
          }
        } else if (value < 0 || value > 100 || value == '') {
          this.form.discount = 0
          this.form.priceWithDiscount = this.form.price
        } else {
          this.form.priceWithDiscount = this.form.price - (value / 100) * this.form.price
        }
      }, 10);
    },
    calculateFromPriceWithDiscount(value) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (this.currency != '%') {
          if (value < 0 || value == '') {
            this.form.priceWithDiscount = 0
          }
          this.form.price = value + this.form.discount
        } else {
          if (value < 0 || value == '') {
            this.form.priceWithDiscount = 0
          }
          this.form.price = this.form.priceWithDiscount / (1 - (this.form.discount / 100));
        }
      }, 10);
    },
    onSubmit() {
      this.buttonSubmited = true
      try {
        if (this.buttonSubmited && this.form.priceWithDiscount < this.form.paidAmount) {
          return;
        }
        let object
        if (this.currency != '%') {
          object = {
            newPrice: this.form.price,
            orderItemId: this.paymentPrice.orderItemId,
            discount: this.form.discount,
          }
        } else {
          object = {
            newPrice: this.form.price,
            orderItemId: this.paymentPrice.orderItemId,
            discount: this.form.price - this.form.priceWithDiscount,
          }
        }
        this.$emit('updatePrice', object)
        this.$refs.modal.hide();
        this.onReset()
      } catch (error) {
        console.log(error)
      }
    },
    onCancel() {
      this.$refs.modal.hide();
      this.onReset()
    },
    onReset() {
      this.buttonSubmited = false
      this.form.price = this.paymentPrice.price
      this.form.discount = this.paymentPrice.discount
      this.form.priceWithDiscount = this.paymentPrice.priceWithDiscount
      this.form.paidAmount = this.paymentPrice.paidAmount
    },
  },
}
</script>

<template>
  <div>
    <b-modal
      id="edit-shipment-price-total"
      ref="modal"
      header-class="headerModal"
      centered
      :title="$t('EditPayment')"
      hide-footer
      no-close-on-backdrop
      @close="onCancel"
    >
      <b-form>
        <b-form-group :label="$t('Price')">
          <b-form-input
            v-model.number="form.price"
            type="number"
          />
        </b-form-group>
        <b-form-group :label="$t('PaidAmount')">
          <b-form-input
            v-model.number="form.paidAmount"
            type="number"
            disabled
          />
        </b-form-group>
        <p
          v-if="buttonSubmited && form.price < form.paidAmount"
          style="color:red; margin-bottom: 5px;;"
        >
          {{ $t('Paid cannot be bigger than price') }}
        </p>
        <div class="buttonsEverywhere">
          <button
            type="button"
            variant="none"
            class="buttonSubmit"
            style="margin-top: 10px;"

            @click="onSubmit"
          >
            {{ $t('Submit') }}
          </button>
          <b-button
            type="button"
            variant="danger"
            class="buttonCancel"
            style="margin-right: 15px; margin-top: 10px; background: white; color: black; border-color: white; font-weight: 400;"

            @click="onCancel"
          >
            {{ $t('Cancel') }}
          </b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
// import moment from 'moment'
// import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  components: {
    // DatePicker,
  },
  props: ['paymentPrice', 'priceOrderId'],
  data() {
    return {
      form: {
        price: 0,
      },
      buttonSubmited: false,
    }
  },
  computed: {
    ...mapGetters([
    ]),
  },
  watch: {
    paymentPrice(value) {
      this.form.price = value.shipmentPrice
      this.form.paidAmount = value.paidAmount
    },
  },
  methods: {
    onSubmit() {
      this.buttonSubmited = true
      try {
        if (this.buttonSubmited && this.form.price < this.form.paidAmount) {
          return;
        }
        const object = {
          newPrice: this.form.price,
          orderId: this.priceOrderId,
        }
        this.$emit('updateShipment', object)
        this.$refs.modal.hide();
        this.onReset()
      } catch (error) {
        console.log(error)
      }
    },
    onCancel() {
      this.$refs.modal.hide();
      this.onReset()
    },
    onReset() {
      this.buttonSubmited = false
      this.form.price = this.paymentPrice.price
      this.form.discount = this.paymentPrice.discount
      this.form.priceWithDiscount = this.paymentPrice.priceWithDiscount
      this.form.paidAmount = this.paymentPrice.paidAmount
    },
  },
}
</script>
